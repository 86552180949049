import React, { MouseEvent, useCallback } from 'react'
import { IconName, staticImportIcon } from '@/utils/static-import-icon'
import { Avatar, Button, Stack } from '@mui/material'
import { useRouter } from 'next/router'

import Image from 'next/image'
import useIwAuth from '@/hooks/use-iw-auth'
import useTrans from '@/hooks/useTrans'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'

import LanguageSwitch from '../components/language-switch'

interface RightBarProps {
  handleOpenProfileMenu: (event: React.MouseEvent<HTMLElement>) => void
}

function RightBar(props: RightBarProps) {
  const { handleOpenProfileMenu } = props
  const isMobile = useMobileDetection()
  const { isLoggedIn, currentUser } = useIwAuth()
  const trans = useTrans()
  const router = useRouter()

  const handleLogin = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      router.push({ pathname: '/login', query: { callback_url: router.pathname } })
    },
    [router],
  )

  if (isMobile)
    return (
      <Stack direction="row" gap={1}>
        <LanguageSwitch />
        <Button
          className="bg-iw-primary-light text-sm text-iw-primary py-2 px-4 sm:px-10 flex items-center gap-2"
          onClick={handleOpenProfileMenu}
        >
          {trans.common.menu}
          <Image height={20} src={staticImportIcon(IconName.MENU_ICON)} alt={IconName.MENU_ICON} />
        </Button>
      </Stack>
    )
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      className="sm:w-fit"
    >
      <LanguageSwitch />
      {isLoggedIn && currentUser ? (
        <Avatar
          src={currentUser.image || '/images/avatars/1.jpg'}
          alt={currentUser.name || 'unknown-user'}
          className="text-iw-primary w-12 h-12 cursor-pointer"
          onClick={handleOpenProfileMenu}
        />
      ) : (
        <Button
          className="bg-iw-gradient-primary hover:bg-iw-primary-blue text-white py-2 px-6 flex items-center gap-2"
          onClick={handleLogin}
        >
          {trans.common.login}
          <Image
            height={20}
            src={staticImportIcon(IconName.LOGIN_ICON)}
            alt={IconName.LOGIN_ICON}
          />
        </Button>
      )}
    </Stack>
  )
}

export default RightBar
