import React, { memo, useCallback, useEffect, useState } from 'react'
import { AppBar, Container, Menu, Stack } from '@mui/material'

import InstantwireLogo from '@/components/base/InstantwireLogo'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import MainMenu from './MainMenu'
import NavBar from './NavBar'
import RightBar from './RightBar'

const Header = () => {
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isMobile = useMobileDetection()
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setIsOpenProfileMenu(open)
    },
    [],
  )
  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isMobile) setAnchorEl(event.currentTarget)
    toggleDrawer(true)(event)
  }

  const handleCloseProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isMobile) setAnchorEl(null)
    toggleDrawer(false)(event)
  }

  if (!isClient) return <></>
  return (
    <>
      <AppBar color="transparent" className="shadow-none min-h-[4.5rem] px-0 bg-white border-b border-solid border-[#CECECE] z-10" position="static">
        <Container maxWidth="xl" className="min-h-[4.5rem] flex items-center">
          <Stack
            direction="row"
            justifyContent="space-between"
            justifyItems="center"
            alignItems="center"
            className="flex-wrap w-full h-full"
          >
            <InstantwireLogo />
            <NavBar />
            <RightBar handleOpenProfileMenu={handleOpenProfileMenu} />
          </Stack>
        </Container>
      </AppBar>

      {!isMobile ? (
        <>
          <Menu
            className="mt-10 hidden sm:flex"
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseProfileMenu}
            anchorEl={anchorEl}
            PaperProps={{
              className: 'bg-white rounded-xl',
            }}
          >
            <MainMenu handleCloseMenu={handleCloseProfileMenu} />
          </Menu>
        </>
      ) : (
        <SwipeableDrawer
          anchor="right"
          className="flex sm:hidden"
          PaperProps={{
            className: 'bg-white',
          }}
          open={isOpenProfileMenu}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={30}
          hysteresis={1}
          sx={{
            '& .MuiPaper-root': {
              width: '360px',
            },
          }}
        >
          <MainMenu handleCloseMenu={handleCloseProfileMenu} />
        </SwipeableDrawer>
      )}
    </>
  )
}

export default memo(Header)
