import { useAuthorizeRequest } from './use-authorize-request'

export const useFeatureFlags = () => {
  return useAuthorizeRequest({
    path: '/instantwire/settings/features',
    method: 'GET',
    allowAnonymous: true,
  })
}

