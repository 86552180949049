import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useFeatureFlags } from '@/hooks/use-feature-flags'
import { IconName, staticImportIcon } from '@/utils/static-import-icon'
import { v4 as uuidv4 } from 'uuid'

import Image from 'next/image'
import useIwAuth from '@/hooks/use-iw-auth'
import useTrans from '@/hooks/useTrans'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'

type MenuItem = {
  value: string
  label: string
  icon: string
  isLoggedIn: boolean
}

type MainMenuProps = {
  handleCloseMenu: (event: React.MouseEvent<HTMLElement>) => void
}

const MainMenu = (props: MainMenuProps) => {
  const router = useRouter()
  const isMobile = useMobileDetection()
  const trans = useTrans()
  const [isClient, setIsClient] = useState(false)
  const { data: featureFlags } = useFeatureFlags()

  const { handleCloseMenu } = props

  const handleLogout = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      signOut({ callbackUrl: '/landing' })
      handleCloseMenu(e)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router, handleCloseMenu],
  )

  const menus = useMemo(() => {
    return [
      {
        value: 'kyc',
        label: trans.account.kyc,
        icon: IconName.MENU_KYC_ICON,
        isLoggedIn: true,
      },
      // {
      //   value: 'balances',
      //   label: trans.account.balances,
      //   icon: IconName.MENU_BALANCES_ICON,
      //   isLoggedIn: true,
      // },
      {
        value: 'recipients',
        label: trans.account.recipients,
        icon: IconName.MENU_RECIPIENTS_ICON,
        isLoggedIn: true,
      },
      {
        value: 'transaction-history',
        label: trans.account.transaction_history,
        icon: IconName.MENU_TRANSACTION_HISTORY_ICON,
        isLoggedIn: true,
      },
      {
        value: 'referral',
        label: trans.account.exclusive_deals,
        icon: IconName.MENU_REFERRAL_ICON,
        isLoggedIn: false,
      },
      {
        value: 'wire-race',
        label: trans.account.wire_race,
        icon: IconName.MENU_WIRE_RACE_ICON,
        isLoggedIn: false,
      },
    ]
  }, [trans])

  useEffect(() => {
    setIsClient(true)
  }, [])
  if (!isClient) return <></>
  return (
    <Stack className="h-full w-full" justifyContent="space-between">
      {!isMobile ? (
        <MainMenuForDesktop menus={menus} featureFlags={featureFlags} handleLogout={handleLogout} />
      ) : (
        <MainMenuForMobile
          menus={menus}
          featureFlags={featureFlags}
          handleLogout={handleLogout}
          handleCloseMenu={handleCloseMenu}
        />
      )}
    </Stack>
  )
}

const GetNewLinkMenu = (menu: string) => {
  return ['kyc', 'balances', 'recipients'].includes(menu) ? `/account?tab=${menu}` : `/${menu}`
}

const MainMenuForMobile = ({
  menus,
  featureFlags,
  handleLogout,
  handleCloseMenu,
}: {
  menus: MenuItem[]
  featureFlags: any
  handleLogout: (e: MouseEvent<HTMLElement>) => void
  handleCloseMenu: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  const trans = useTrans()
  const { isLoggedIn, currentUser } = useIwAuth()
  return (
    <Stack className="relative h-full" justifyContent="space-between" gap={1}>
      <IconButton
        className="absolute right-4 top-4 text-iw-neutral-black"
        aria-label="close"
        onClick={handleCloseMenu}
      >
        <CloseIcon fontSize="medium" />
      </IconButton>

      {isLoggedIn && currentUser ? (
        <Stack className="mt-20" alignItems="center" gap={1}>
          <Avatar
            key="my-avatar"
            src={currentUser.image || '/images/avatars/1.jpg'}
            alt={currentUser.name || 'unknown-user'}
            className="text-iw-primary w-20 h-20"
          />
          <Typography className="text-iw-primary font-bold">{currentUser.email}</Typography>
        </Stack>
      ) : (
        <Link
          className="bg-iw-gradient-primary hover:bg-iw-primary-blue mt-20 mx-8 p-4 rounded-full font-bold text-white text-center"
          href="/login"
        >
          {trans.common.login}
        </Link>
      )}

      <List component="nav" aria-labelledby="menu-list-subheader-mobile" className="flex-1 mt-5">
        {menus
          .filter((m) => !m.isLoggedIn || m.isLoggedIn === isLoggedIn)
          .map((menu) => {
            if (menu.value === 'kyc' && !featureFlags?.kyc_feature) return <></>
            const link = GetNewLinkMenu(menu.value)
            return (
              <ListItemButton
                component="a"
                href={link}
                className="px-8"
                key={`menu-mobile-${uuidv4()}`}
              >
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Image src={staticImportIcon(menu.icon)} alt={menu.icon} />
                </ListItemIcon>
                <ListItemText primary={menu.label} />
              </ListItemButton>
            )
          })}
      </List>

      {isLoggedIn && (
        <Stack
          className="cursor-pointer px-8 py-4 mb-10 text-red-600"
          direction="row"
          spacing={1}
          alignItems="center"
          onClick={handleLogout}
        >
          <LogoutIcon sx={{ minWidth: 30 }} className="w-6 h-6 text-red-600" />
          <Typography className="text-normal">{trans.common.logout}</Typography>
        </Stack>
      )}
    </Stack>
  )
}

const MainMenuForDesktop = ({
  menus,
  featureFlags,
  handleLogout,
}: {
  menus: MenuItem[]
  featureFlags: any
  handleLogout: (e: MouseEvent<HTMLElement>) => void
}) => {
  const trans = useTrans()
  const { isLoggedIn } = useIwAuth()

  if(!isLoggedIn) return <></>
  return (
    <Stack justifyContent="space-between" className="h-full">
      <List component="nav" aria-labelledby="menu-list-subheader-desktop" className="flex-1">
        {menus.map((menu) => {
          if (menu.value === 'kyc' && !featureFlags?.kyc_feature) return <></>
          const link = GetNewLinkMenu(menu.value)
          return (
            <ListItemButton
              component="a"
              href={link}
              className="px-8"
              key={`menu-desktop-${uuidv4()}`}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Image src={staticImportIcon(menu.icon)} alt={menu.icon} />
              </ListItemIcon>
              <ListItemText primary={menu.label} />
            </ListItemButton>
          )
        })}
        <ListItemButton
          component="button"
          onClick={handleLogout}
          className="px-8 text-red-600 w-full"
          key={'menu-desktop-logout'}
        >
          <ListItemIcon sx={{ minWidth: 30 }}>
            <LogoutIcon className="w-6 h-6 text-red-600" />
          </ListItemIcon>
          <ListItemText primary={trans.common.logout} />
        </ListItemButton>
      </List>
    </Stack>
  )
}

export default memo(MainMenu)
