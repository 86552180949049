import { Link, Stack } from '@mui/material'
import React, { useMemo } from 'react'
import useTrans from '@/hooks/useTrans'

function NavBar() {
  const trans = useTrans()
  const menus = useMemo(
    () => [
      {
        name: trans.header.home,
        url: '/',
      },
      {
        name: trans.header.referral,
        url: '/referral',
      },
      {
        name: trans.header.wire_race,
        url: '/wire-race',
      },
      {
        name: trans.header.transaction,
        url: '/transaction-history',
      },
    ],
    [trans],
  )
  return (
    <Stack direction="row" className="hidden sm:flex" alignItems="center" spacing={3}>
      {menus.map((menu, index) => (
        <Link
          key={`header-nav-item-${index}`}
          className="text-black no-underline hover:text-iw-secondary"
          href={menu.url}
        >
          {menu.name}
        </Link>
      ))}
    </Stack>
  )
}

export default NavBar
