import { MouseEvent, useCallback, useContext, useState, useEffect } from 'react'
import { Box, ButtonBase, Menu, MenuItem } from '@mui/material'
import { LanguageContext } from '@/providers/language'
import { useUser } from '@/root/src/hooks/use-user'
import { useIntercom } from '@thanhvo102/react-use-intercom'

import axios from 'axios'
import Image from 'next/image'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const COUNTRY_CODE_MAPPER = {
  vi: 'Tiếng Việt',
  en: 'English',
} as any

const SHORT_COUNTRY_MAPPER = {
  vi: 'VIE',
  en: 'ENG',
} as any

const LanguageSwitch = () => {
  const { lang, handleChangeLanguage } = useContext(LanguageContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const { data: user } = useUser()
  const { update } = useIntercom()

  const updateIntercomUser = useCallback(async () => {
    if (user) {
      const { data: userHash } = await axios.get('/api/intercom_user_hash')
      update({
        email: user.email,
        customAttributes: {
          locale: lang,
        },
        userHash,
      })
    }
  }, [lang, update, user])

  useEffect(() => {
    updateIntercomUser()
  }, [updateIntercomUser])

  const open = Boolean(anchorEl)
  return (
    <>
      <ButtonBase
        id="btn-preferences-settings"
        aria-controls={open ? 'menu-preferences-settings' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="text-zinc-500 hover:text-iw-secondary"
        disableRipple
      >
        {(isClient && SHORT_COUNTRY_MAPPER[lang]) || 'ENG'}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ButtonBase>
      <Menu
        id="menu-preferences-settings"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'btn-preferences-settings',
        }}
        PaperProps={{ className: 'translate-y-2' }}
        sx={{
          '& .MuiPaper-root .MuiList-root': {
            backgroundColor: 'white',
            color: 'black',
            padding: 0,
            '& .Mui-selected': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: () => 'white',
            },
          },
        }}
      >
        {Object.keys(COUNTRY_CODE_MAPPER).map((code) => (
          <MenuItem
            key={code}
            selected={lang === code}
            onClick={() => {
              handleChangeLanguage(code)
              handleClose()
            }}
            data-testid={`btn-change-language-to-${code}`}
          >
            <Box className="w-[40px]">
              <Image src={`/lang-${code}-icon.webp`} alt={code} width={32} height={22} />
            </Box>
            {COUNTRY_CODE_MAPPER[code]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSwitch
